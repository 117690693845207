<template>
  <div class="abnormal-broadcast">
    <div class="title-color flex j-between">
      报警播报
    </div>
    <div class="noData"
         v-if="abnormalBroadcast.length == 0">
      <img src="../../../assets/screen/no_data.png">
      暂无数据
    </div>
    <div class="middle-right-cont"
         ref="abnormalBroadcast"
         v-else>
      <vue-seamless-scroll :data="abnormalBroadcast"
                           :class-option="alarmOption">
        <div class="item"
             v-for="(item,index) in abnormalBroadcast"
             :key="index">
          <div>{{formatTime(item.time)}}</div>
          <div class="line2">{{item.message}}</div>
        </div>
      </vue-seamless-scroll>
    </div>
  </div>
</template>

<script>
import vueSeamlessScroll from 'vue-seamless-scroll'
import { pollingTime } from './largeScreenConfig'
export default {
  components: {
    vueSeamlessScroll,
  },
  data () {
    return {
      abnormalBroadcast: [],
      alarmOption: {
        limitMoveNum: 7,
        singleHeight: 55,
        waitTime: 15000
      },
    }
  },
  computed: {
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.getAbnormalData()
      setInterval(() => {
        this.getAbnormalData()
      }, pollingTime);
    },
    // 获取异常数据
    getAbnormalData () {
      this.$http.getScreenAbnormalBroadcast({}).then((res) => {
        if (res.code == 200) {
          this.abnormalBroadcast = res.result
          if (this.abnormalBroadcast.length) {
            this.$nextTick(() => {
              let height = this.$refs.abnormalBroadcast.offsetHeight / 6
              this.alarmOption.singleHeight = parseInt(height)
            })
          }
        }
      })
    },
    // 作业概况时间 去掉年月日
    formatTime (time) {
      if (time) {
        let arr = time.split(' ')
        let newArr = arr[1].split(':')
        return newArr[0] + ':' + newArr[1]
      } else {
        return ''
      }
    },
  },
}
</script>

<style lang='scss' scoped>
.abnormal-broadcast {
  padding: 0.1736vw 0.5208vw;
  background: url("../../../assets/screen/abnormal_broadcast_bg_new.png")
    no-repeat;
  background-size: 100% 100%;
  .title-color {
    padding-top: 0.8681vw;
    padding-left: 1.7361vw;
    font-size: 0.6597vw;
  }
  .middle-right-cont {
    height: 17.5347vw;
    margin-top: 1vw;
    margin-left: 0.5208vw;
    overflow: hidden;
    .item {
      width: 14.0625vw;
      height: 2.23vw;
      border: 0.0174vw solid #00cbff;
      display: flex;
      align-items: center;
      margin-bottom: 0.6944vw;
      padding: 0 0.8681vw;
      font-size: 0.5208vw;
      div:first-child {
        min-width: 3.125vw;
        border-right: 0.0347vw solid #02617b;
        padding-right: 1.1285vw;
        margin-right: 1.1285vw;
        text-align: center;
      }
    }
  }
  .noData {
    margin-top: 20%;
    img {
      width: 6.9444vw;
      height: 6.9444vw;
      margin-bottom: 0.1736vw;
    }
  }
}
</style>  