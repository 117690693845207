<template>
  <div class="plan-box">
    <div class="item top">
      <div class="title-color flex j-between">
        应急计划
      </div>
      <div class="item-title flex j-between pang-family">
        <div>应急预案 <span>{{planCount.planCount || 0}}</span> 件</div>
        <div>应急计划 <span>{{planCount.resplanCount || 0}}</span> 件</div>
      </div>
      <div style="z-index:1">
        <Echarts width="13.8889vw"
                 height="6.9444vw"
                 :options="emergencyOptions"
                 ref="emergencyEchartsRef"></Echarts>
      </div>
      <div class="noData"
           v-if="planBroadcast.length == 0">
        <img src="../../../assets/screen/no_data.png">
        暂无数据
      </div>
      <div class="plan"
           ref="planRef"
           v-else>
        <vue-seamless-scroll :data="planBroadcast"
                             :class-option="planOption">
          <div v-for="(item,index) in planBroadcast"
               :key="index"
               class="plan-item flex j-between a-center">
            <div class="line1">{{item.name}} {{item.cont}}({{item.statuName}})</div>
            <div>{{item.time}}</div>
          </div>
        </vue-seamless-scroll>
      </div>
    </div>
  </div>
</template>

<script>
import bus from '@/utils/bus'
import Echarts from '@/components/common/Echarts';
import * as echarts from 'echarts'
import vueSeamlessScroll from 'vue-seamless-scroll'
import { pollingTime } from './largeScreenConfig'
export default {
  components: { Echarts, vueSeamlessScroll },
  data () {
    return {
      planCount: {},
      planBroadcast: [],
      emergencyOptions: {
        tooltip: {},
        title: {
          text: '计划下发趋势（ 年）',
          textStyle: {
            fontSize: 12,
            color: "#fff"
          },
          top: 10,
          left: '6%',
        },
        animation: false,
        grid: {
          top: "40%",
          bottom: "20%"
        },
        xAxis: {
          data: [],
          axisLine: {
            show: true, //隐藏X轴轴线
            lineStyle: {
              color: '#11417a'
            }
          },
          axisTick: {
            show: false //隐藏X轴刻度
          },
          axisLabel: {
            show: true,
            margin: 14,
            fontSize: 14,
            textStyle: {
              color: "#A3C0DF" //X轴文字颜色
            }
          },

        },
        yAxis: [
          {
            type: "value",
            gridIndex: 0,
            min: 0,
            // max: 100,
            interval: 1,
            // splitNumber: 4,
            splitLine: {
              show: true,
              lineStyle: {
                color: '#113763',
                width: 1
              },
            },
            axisTick: {
              show: false
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: '#11417a'
              }
            },
            axisLabel: {
              show: true,
              margin: 14,
              fontSize: 14,
              textStyle: {
                color: "#A3C0DF" //X轴文字颜色
              }
            },
          },
        ],
        series: [
          {
            // name: "主营业务",
            type: "bar",
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: "#07ecd9"
                },
                {
                  offset: 1,
                  color: "#034881"
                }
                ])
              }
            },
            data: [],
            z: 10,
            zlevel: 0,
            label: {
              show: true,
              position: "top",
              distance: 10,
              fontSize: 14,
              color: "#01fff4",
              formatter: (params) => {
                return params.value ? params.value : ''
              },
            }
          },
          {
            // 分隔
            type: "pictorialBar",
            itemStyle: {
              normal: {
                color: "#0F375F"
              }
            },
            symbolRepeat: "fixed",
            symbolMargin: 6,
            symbol: "rect",
            symbolClip: true,
            symbolSize: [20, 2],
            symbolPosition: "start",
            symbolOffset: [0, -1],
            // symbolBoundingData: this.total,
            data: [],
            width: 25,
            z: 0,
            zlevel: 1,
          },
        ]
      },
      planOption: {
        limitMoveNum: 6,
        singleHeight: 33,
        waitTime: 15000
      }
    };
  },
  computed: {
  },
  mounted () {
    this.init()
  },
  destroyed () {
    bus.$off('resizeWidth')
  },
  methods: {
    init () {
      bus.$on("resizeWidth", data => {
        this.resizeWidth(data)
      })
      this.getPlanCount()
      this.getPlanBroadcast()
      this.getPlanEcharts()
      setInterval(() => {
        this.getPlanCount()
        this.getPlanBroadcast()
        this.getPlanEcharts()
      }, pollingTime);
      // this.getAnmation()
    },
    getAnmation () {
      let arr = this.$refs.personLineAnimationRef
      let i = 0
      setInterval(() => {
        if (arr[i]) {
          arr[i].style.opacity = 1
        }
        if (arr[i - 1]) {
          arr[i - 1].style.opacity = 1
        }
        if (arr[i - 2]) {
          arr[i - 2].style.opacity = 1
        }
        if (arr[i - 3]) {
          arr[i - 3].style.opacity = 1
        }
        if (arr[i - 4]) {
          arr[i - 4].style.opacity = 1
        }
        if (arr[i - 5]) {
          arr[i - 5].style.opacity = 1
        }
        arr.forEach((item, index) => {
          if (index != i && index != i - 1 && index != i - 2 && index != i - 3 && index != i - 4 && index != i - 5) {
            arr[index].style.opacity = .5
          }
        })
        if (i == arr.length + 1) {
          i = 0
        } else {
          i++
        }
      }, 50);
    },
    // 应急计划数量
    getPlanCount () {
      this.$http.getScreenPlanCount().then((res) => {
        if (res.code === 200) {
          this.planCount = res.result
        }
      })
    },
    // 应急播报
    getPlanBroadcast () {
      this.$http.getScreenPlanBroadcast().then((res) => {
        if (res.code === 200) {
          this.planBroadcast = res.result
          // this.planBroadcast = [{ statuName: "已下发", name: "数据大屏", time: "2021-11-10 11:22:52", content: "" }, { statuName: "已下发", name: "数据大屏", time: "2021-11-10 11:22:52", content: "" }, { statuName: "已下发", name: "数据大屏", time: "2021-11-10 11:22:52", content: "" }, { statuName: "已下发", name: "数据大屏", time: "2021-11-10 11:22:52", content: "" }, { statuName: "已下发", name: "数据大屏", time: "2021-11-10 11:22:52", content: "" }, { statuName: "已下发", name: "数据大屏", time: "2021-11-10 11:22:52", content: "" }, { statuName: "已下发", name: "数据大屏", time: "2021-11-10 11:22:52", content: "" }, { statuName: "已下发", name: "数据大屏", time: "2021-11-10 11:22:52", content: "" }, { statuName: "已下发", name: "数据大屏", time: "2021-11-10 11:22:52", content: "" },]
          if (this.planBroadcast.length) {
            this.$nextTick(() => {
              let height = this.$refs.planRef.offsetHeight / 5
              this.planOption.singleHeight = parseInt(height)
            })
          }
        }
      })
    },
    // 应急图表
    getPlanEcharts () {
      this.$http.getScreenPlanEcharts().then((res) => {
        if (res.code === 200) {
          let data = res.result
          let x = data.map(item => {
            return item.month
          })
          let y = data.map(item => {
            return item.count
          })
          this.emergencyOptions.xAxis.data = x
          this.emergencyOptions.series[0].data = y
          this.emergencyOptions.series[1].data = y
          this.$refs.emergencyEchartsRef.init()
        }
      })
    },
    // 作业概况时间 去掉年月日
    formatTime (time) {
      if (time) {
        let arr = time.split(' ')
        let newArr = arr[1].split(':')
        return newArr[0] + ':' + newArr[1]
      } else {
        return ''
      }
    },
    resizeWidth (data) {
      switch (data.status) {
        case 0:
          this.emergencyOptions.title.textStyle.fontSize = 12
          this.emergencyOptions.xAxis.axisLabel.fontSize = 14
          this.emergencyOptions.yAxis[0].axisLabel.fontSize = 14
          this.emergencyOptions.series[0].label.fontSize = 14
          break;
        case 1:
          this.emergencyOptions.title.textStyle.fontSize = 18
          this.emergencyOptions.xAxis.axisLabel.fontSize = 25
          this.emergencyOptions.yAxis[0].axisLabel.fontSize = 25
          this.emergencyOptions.series[0].label.fontSize = 25

          break;
        case 2:
          this.emergencyOptions.title.textStyle.fontSize = 25
          this.emergencyOptions.xAxis.axisLabel.fontSize = 30
          this.emergencyOptions.yAxis[0].axisLabel.fontSize = 30
          this.emergencyOptions.series[0].label.fontSize = 30

          break;
        default:
          break;
      }
      this.$refs.emergencyEchartsRef.resize()
    },
  },
}
</script>

<style lang='scss' scoped>
.plan-box {
  position: relative;
  background: url("../../../assets/screen/plan_big_bg.png") no-repeat;
  background-size: 100% 100%;
  .animation-line {
    height: 5.5903vw;
    position: absolute;
    top: 0;
    right: -0.2604vw;
    div {
      width: 0.1736vw;
      height: 0.1736vw;
      margin-bottom: 0.1736vw;
      background: #b7cffc;
      opacity: 0.5;
    }
  }
  .item {
    margin-left: 1.2153vw;
    padding: 0.1736vw 0.3472vw 0 1.4757vw;
    position: relative;
    .title-color {
      padding-top: 0.6944vw;
      padding-left: 1.9097vw;
      font-size: 0.6597vw;
    }
    .item-title {
      margin-top: 0.5vw;
      margin-left: 0.5208vw;
      font-size: 0.5903vw;
      div:first-child {
        width: 5.3819vw;
        line-height: 1.3021vw;
        background: url("../../../assets/screen/resplan_bg.png") no-repeat;
        span {
          color: #00c3f6;
        }
      }
      div:last-child {
        width: 5.3819vw;
        line-height: 1.3021vw;
        background: url("../../../assets/screen/plan_bg.png") no-repeat;
        span {
          color: #f2c202;
        }
      }
      div {
        font-size: 0.5903vw;
        text-align: left;
        padding-left: 0.8vw;
      }
    }
    .noData {
      img {
        width: 4.3403vw;
        height: 4.3403vw;
        margin-bottom: 0.1736vw;
      }
    }
    .plan {
      height: 8.6806vw;
      overflow: hidden;
      color: #00cbff;
      margin-top: 0.3vw;
      margin-left: 0.3472vw;
      font-size: 0.5208vw;
      .plan-item {
        width: 100%;
        height: 1.3021vw;
        margin-bottom: 0.434vw;
        border: 0.0174vw solid #00cbff;
        padding: 0 0.3472vw;
        div:first-child {
          width: 6.5972vw;
        }
        div:last-child {
          min-width: 3.4722vw;
        }
      }
    }
  }
}
</style>  