<template>
  <div class="abnormal-box">
    <div class="title-color flex j-between">
      报警与违规
      <!-- <img class="title-img"
           src="../../../assets/screen/title_icon.png"> -->
    </div>
    <div class="abnormal-box-subtitle">车辆人员行驶报警与作业违规情况</div>
    <div class="pie1">
      <div class="item">
        <div>
          <img src="../../../assets/screen/loop_bg_in.png">
          <span>{{abnormalData.alarmCarNum || 0}}</span>
        </div>
        <p>车辆报警</p>
      </div>
      <div class="item">
        <div>
          <img src="../../../assets/screen/loop_bg_in.png">
          <span>{{abnormalData.carViolationNum || 0}}</span>
        </div>
        <p>车辆违规</p>
      </div>
      <div class="item">
        <div>
          <img src="../../../assets/screen/loop_bg_in.png">
          <span>{{abnormalData.alarmSheshiNum || 0}}</span>
        </div>
        <p>设施报警</p>
      </div>
      <div class="item">
        <div>
          <img src="../../../assets/screen/loop_bg_in.png">
          <span>{{abnormalData.alarmPersonNum || 0}}</span>
        </div>
        <p>人员报警</p>
      </div>
      <div class="item">
        <div>
          <img src="../../../assets/screen/loop_bg_in.png">
          <span>{{abnormalData.dlbjViolationNum || 0}}</span>
        </div>
        <p>人员违规</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
  },
  data () {
    return {
      abnormalData: {},
    }
  },
  computed: {
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.getAbnormalData()
    },
    // 获取设施
    // 获取异常数据
    getAbnormalData () {
      this.$http.getScreenAbnormalData().then((res) => {
        if (res.code == 200) {
          this.abnormalData = res.result
        }
      })
    },
  },
}
</script>

<style lang='scss' scoped>
.abnormal-box {
  background: url("../../../assets/screen/abnormal_bg_new.png") no-repeat;
  background-size: 100% 100%;
  .title-color {
    padding-top: 0.8681vw;
    padding-left: 1.7361vw;
    font-size: 0.6597vw;
  }
  .abnormal-box-subtitle {
    margin-top: 1.3889vw;
    margin-left: 1.5625vw;
    font-size: 0.5903vw;
  }
  .pie1 {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1.9097vw;
    // justify-content: space-around;
    .item {
      width: 33.33%;
      text-align: center;
      margin-bottom: 2.7778vw;
      div {
        width: 2.6042vw;
        height: 2.6042vw;
        font-size: 0.7292vw;
        border-radius: 50%;
        margin-bottom: 0.3125rem;
        background: url("../../../assets/screen/loop_bg.png") no-repeat;
        background-size: 2.6042vw 2.6042vw;
        position: relative;
        margin: 0 auto;
        img {
          width: 2.6042vw;
          height: 2.6042vw;
          position: absolute;
          top: 0;
          left: 0;
          animation: img-rotate 2.5s linear infinite;
        }
        span {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      p {
        font-size: 0.5903vw;
        margin-top: 0.3472vw;
      }
    }
  }
}
@keyframes img-rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>  