<template>
  <div class="main"
       ref="main">
    <div class="main-cont"
         ref="contRef">
      <!-- <canvas id="canvas"
              ref="canvasRef"></canvas> -->
      <ScreenTitle class="title"></ScreenTitle>
      <div class="cont flex">
        <div class="line-animation left-line-animation">
          <div v-for="(item,index) in 39"
               :key="index"
               ref="lineAnimationLeftRef"></div>
        </div>
        <div class="first">
          <ScreenWorkBroadcast class="first-work"></ScreenWorkBroadcast>
          <ScreenFacl class="first-facl"></ScreenFacl>
          <ScreenVideo class="first-video"></ScreenVideo>
        </div>
        <div class="second">
          <ScreenPersonWork class="second-person"></ScreenPersonWork>
          <ScreenCarWork class="second-car"></ScreenCarWork>
        </div>
        <div class="third">
          <ScreenMap class="third-map"></ScreenMap>
        </div>
        <div class="fourth">
          <ScreenPlan class="fourth-plan"></ScreenPlan>
          <ScreenCase class="fourth-case"></ScreenCase>
        </div>
        <div class="fifth">
          <ScreenAbnormal class="fifth-abnormal"></ScreenAbnormal>
          <ScreenAbnormalBroadcast class="fifth-abnormal-broadcast"></ScreenAbnormalBroadcast>
        </div>
        <div class="line-animation right-line-animation">
          <div v-for="(item,index) in 39"
               :key="index"
               ref="lineAnimationRightRef"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bus from '@/utils/bus'
import ScreenTitle from "@/components/product/largeScreen/ScreenTitle";
import ScreenMap from "@/components/product/largeScreen/ScreenMap";
import ScreenPersonWork from "@/components/product/largeScreen/ScreenPersonWork";
import ScreenCarWork from "@/components/product/largeScreen/ScreenCarWork";
import ScreenPlan from "@/components/product/largeScreen/ScreenPlan";
import ScreenCase from "@/components/product/largeScreen/ScreenCase";
import ScreenWorkBroadcast from "@/components/product/largeScreen/ScreenWorkBroadcast";
import ScreenFacl from "@/components/product/largeScreen/ScreenFacl";
import ScreenAbnormal from "@/components/product/largeScreen/ScreenAbnormal";
import ScreenAbnormalBroadcast from "@/components/product/largeScreen/ScreenAbnormalBroadcast";
import ScreenVideo from "@/components/product/largeScreen/ScreenVideo";
export default {
  components: {
    ScreenTitle,
    ScreenMap,
    ScreenPersonWork,
    ScreenCarWork,
    ScreenPlan,
    ScreenCase,
    ScreenWorkBroadcast,
    ScreenFacl,
    ScreenAbnormal,
    ScreenAbnormalBroadcast,
    ScreenVideo
  },
  data () {
    return {
      erd: null
    };
  },
  mounted () {
    this.init()
    // this.getCanvas()
  },
  methods: {
    init () {
      //导入element-resize-detector
      let elementResizeDetectorMaker = require("element-resize-detector")
      // 创建实例
      this.erd = elementResizeDetectorMaker()
      this.lineAnimation()
      //监听元素 大小变化
      this.erd.listenTo(this.$refs.main, element => {
        let width = element.offsetWidth
        let height = element.offsetHeight
        let status
        if (width <= 2400) {
          status = 0
        } else if (width > 2400 && width <= 3840) {
          status = 1
        } else if (width > 3840) {
          status = 2
        }
        let data = {
          width,
          status
        }
        bus.$emit("resizeWidth", data)
        // 1vw = 57.6px
        // 5760时fontsize是36
        // 1时fontsize是0.00625
      })
    },
    getCanvas () {
      let that = this
      // 可调参数
      var BACKGROUND_COLOR = "#020f17";   // 背景颜色
      var POINT_NUM = 100;                        // 星星数目
      var POINT_COLOR = "rgba(255,255,255,0.7)";  // 点的颜色
      // var POINT_COLOR = "#fff";  // 点的颜色
      var LINE_LENGTH = 10000;                    // 点之间连线长度(的平方)

      // 创建背景画布
      var cvs = this.$refs.canvasRef;
      cvs.width = this.$refs.contRef.offsetWidth
      cvs.height = this.$refs.contRef.offsetHeight
      // cvs.width = window.innerWidth;
      // cvs.height = window.innerHeight;
      cvs.style.cssText = "\
    position:fixed;\
    top:0px;\
    left:0px;\
    z-index:-1;\
    opacity:1.0;\
    ";
      // document.body.appendChild(cvs);

      var ctx = cvs.getContext("2d");

      var startTime = new Date().getTime();

      //随机数函数
      function randomInt (min, max) {
        return Math.floor((max - min + 1) * Math.random() + min);
      }

      function randomFloat (min, max) {
        191.2
        return (max - min) * Math.random() + min;
      }

      //构造点类
      function Point () {
        this.x = randomFloat(0, cvs.width);
        this.y = randomFloat(0, cvs.height);

        var speed = randomFloat(0.3, 1.4);
        var angle = randomFloat(0, 2 * Math.PI);

        this.dx = Math.sin(angle) * speed;
        this.dy = Math.cos(angle) * speed;

        this.r = 1.2;

        this.color = POINT_COLOR;
      }

      Point.prototype.move = function () {
        this.x += this.dx;
        if (this.x < 0) {
          this.x = 0;
          this.dx = -this.dx;
        } else if (this.x > cvs.width) {
          this.x = cvs.width;
          this.dx = -this.dx;
        }
        this.y += this.dy;
        if (this.y < 0) {
          this.y = 0;
          this.dy = -this.dy;
        } else if (this.y > cvs.height) {
          this.y = cvs.height;
          this.dy = -this.dy;
        }
      }

      Point.prototype.draw = function () {
        ctx.fillStyle = this.color;   // 填充绘画的颜色、渐变或模式
        ctx.beginPath();    // 开始一条路径，或重置当前的路径。
        ctx.arc(this.x, this.y, this.r, 0, Math.PI * 2);    // 创建弧/曲线（用于创建圆形或部分圆）
        ctx.closePath();    // 从当前点回到起始点的路径
        ctx.fill();   // 填充当前绘图（路径）
      }

      var points = [];

      function initPoints (num) {
        for (var i = 0; i < num; ++i) {
          points.push(new Point());
        }
      }

      // var p0 = new Point(); //鼠标
      // p0.dx = p0.dy = 0;
      // var degree = 2.5;
      // document.onmousemove = function (ev) {
      //   p0.x = ev.clientX;
      //   p0.y = ev.clientY;
      // }
      // document.onmousedown = function (ev) {
      //   degree = 5.0;
      //   p0.x = ev.clientX;
      //   p0.y = ev.clientY;
      // }
      // document.onmouseup = function (ev) {
      //   degree = 2.5;
      //   p0.x = ev.clientX;
      //   p0.y = ev.clientY;
      // }
      // window.onmouseout = function () {
      //   p0.x = null;
      //   p0.y = null;
      // }

      function drawLine (p1, p2, deg) {
        var dx = p1.x - p2.x;
        var dy = p1.y - p2.y;
        var dis2 = dx * dx + dy * dy;
        if (dis2 < 2 * LINE_LENGTH) {
          // 鼠标引力
          // if (dis2 > LINE_LENGTH) {
          //   if (p1 === p0) {
          //     p2.x += dx * 0.03;
          //     p2.y += dy * 0.03;
          //   } else return;
          // }
          var t = (1.05 - dis2 / LINE_LENGTH) * 0.2 * deg;
          ctx.strokeStyle = "rgba(255,255,255," + t + ")";
          ctx.beginPath();
          ctx.lineWidth = 1.5;
          ctx.moveTo(p1.x, p1.y);
          ctx.lineTo(p2.x, p2.y);
          ctx.closePath();
          ctx.stroke();
        }
        return;
      }

      //绘制每一帧
      function drawFrame () {
        cvs.width = that.$refs.contRef.offsetWidth
        cvs.height = that.$refs.contRef.offsetHeight
        ctx.fillStyle = BACKGROUND_COLOR;
        ctx.fillRect(0, 0, cvs.width, cvs.height);

        // 鼠标引力
        // var arr = (p0.x == null ? points : [p0].concat(points));
        var arr = points
        for (var i = 0; i < arr.length; ++i) {
          for (var j = i + 1; j < arr.length; ++j) {
            drawLine(arr[i], arr[j], 1.0);
          }
          arr[i].draw();
          arr[i].move();
        }

        window.requestAnimationFrame(drawFrame);
      }

      initPoints(POINT_NUM);
      drawFrame();
    },
    lineAnimation () {
      let arrLeft = this.$refs.lineAnimationLeftRef
      let arrRight = this.$refs.lineAnimationRightRef
      let i = 0
      setInterval(() => {
        if (arrLeft[i]) {
          arrLeft[i].style.opacity = 1
        }
        if (arrLeft[i - 1]) {
          arrLeft[i - 1].style.opacity = 1
        }
        if (arrLeft[i - 2]) {
          arrLeft[i - 2].style.opacity = 1
        }
        if (arrLeft[i - 3]) {
          arrLeft[i - 3].style.opacity = 1
        }
        if (arrLeft[i - 4]) {
          arrLeft[i - 4].style.opacity = 1
        }
        if (arrLeft[i - 5]) {
          arrLeft[i - 5].style.opacity = 1
        }
        arrLeft.forEach((item, index) => {
          if (index != i && index != i - 1 && index != i - 2 && index != i - 3 && index != i - 4 && index != i - 5) {
            arrLeft[index].style.opacity = .5
          }
        })
        if (i == arrLeft.length + 1) {
          i = 0
        } else {
          i++
        }


        if (arrRight[i]) {
          arrRight[i].style.opacity = 1
        }
        if (arrRight[i - 1]) {
          arrRight[i - 1].style.opacity = 1
        }
        if (arrRight[i - 2]) {
          arrRight[i - 2].style.opacity = 1
        }
        if (arrRight[i - 3]) {
          arrRight[i - 3].style.opacity = 1
        }
        if (arrRight[i - 4]) {
          arrRight[i - 4].style.opacity = 1
        }
        if (arrRight[i - 5]) {
          arrRight[i - 5].style.opacity = 1
        }
        arrRight.forEach((item, index) => {
          if (index != i && index != i - 1 && index != i - 2 && index != i - 3 && index != i - 4 && index != i - 5) {
            arrRight[index].style.opacity = .5
          }
        })
        if (i == arrRight.length + 1) {
          i = 0
        } else {
          i++
        }
      }, 50);
    },
  },
}
</script>

<style lang='scss' scoped>
.main {
  height: 100%;
  letter-spacing: 0.0174vw;
  color: #fff;
  background: #000;
  // position: relative;
  .main-cont {
    background: #020f17;
    position: relative;
    padding-bottom: 0.8681vw;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    .line-animation {
      position: absolute;
      div {
        width: 0.5208vw;
        height: 0.5208vw;
        margin-bottom: 0.5208vw;
        background: #b7cffc;
        opacity: 0.5;
      }
    }
    .left-line-animation {
      left: 0.3472vw;
    }
    .right-line-animation {
      right: 0.2604vw;
    }
    .title {
      width: 100vw;
    }
    .cont {
      margin: 0 1.2153vw;
      margin-top: 1.3889vw;
      .first {
        .first-work {
          width: 15.9722vw;
          height: 12.1528vw;
        }
        .first-facl {
          width: 15.9722vw;
          height: 10.7639vw;
          margin-top: 0.8681vw;
        }
        .first-video {
          width: 15.9722vw;
          height: 15.4514vw;
          margin-top: 0.8681vw;
        }
      }
      .second {
        margin-left: 1.0417vw;
        .second-person {
          width: 17.1875vw;
          height: 19.9653vw;
        }
        .second-car {
          width: 18.2292vw;
          height: 19.0972vw;
          margin-top: 1.0417vw;
        }
      }
      .third {
        margin: 0 -2.2917vw;
        .third-map {
          width: 31.25vw;
          height: 40.191vw;
        }
      }
      .fourth {
        margin-right: 1.0417vw;
        .fourth-plan {
          width: 17.5868vw;
          height: 19.9653vw;
          margin-left: 1.0417vw;
        }
        .fourth-case {
          width: 18.5764vw;
          height: 18.9236vw;
          margin-top: 1.0417vw;
        }
      }
      .fifth {
        .fifth-abnormal {
          width: 16.1458vw;
          height: 17.5347vw;
        }
        .fifth-abnormal-broadcast {
          width: 16.1458vw;
          height: 21.441vw;
          margin-top: 0.8681vw;
        }
      }
    }
  }
}
</style>  