<template>
  <div class="video">
    <div class="title-color flex j-between">
      监控中心
      <div class="flex a-center">
        <img src="../../../assets/screen/refresh.png"
             class="cursor-pointer title-img"
             @click="reloadVideo">
      </div>
    </div>
    <div class="item-cont">
      <div v-for="item in video"
           :key="item.channelid + '_' + item.equipcode"
           v-show="videoShow">
        <SocketVideoPlayer :dataId="item.equipcode"
                           :channelId="item.channelid"
                           style="width:100%;height:100%"
                           ref="socketVideo"
                           v-if="item.eqptype==='车载监控'"></SocketVideoPlayer>
        <HttpVideoPlayer :dataId="item.equipcode"
                         :channelId="item.channelid"
                         style="width:100%;height:100%"
                         v-if="item.eqptype==='视频设备'"></HttpVideoPlayer>
      </div>
      <div v-for="item in videoPrepare"
           :key="item.channelid + '_' + item.equipcode"
           v-show="!videoShow">
        <SocketVideoPlayer :dataId="item.equipcode"
                           :channelId="item.channelid"
                           style="width:100%;height:100%"
                           ref="socketVideo"
                           v-if="item.eqptype==='车载监控'"></SocketVideoPlayer>
        <HttpVideoPlayer :dataId="item.equipcode"
                         :channelId="item.channelid"
                         style="width:100%;height:100%"
                         tipDisable
                         v-if="item.eqptype==='视频设备'"></HttpVideoPlayer>
      </div>
    </div>
  </div>
</template>

<script>
import SocketVideoPlayer from '@/components/common/SocketVideoPlayer';
import HttpVideoPlayer from '@/components/common/HttpVideoPlayer';
export default {
  components: {
    SocketVideoPlayer,
    HttpVideoPlayer
  },
  data () {
    return {
      video: [],
      videoPrepare: [],
      videoAllArr: [],
      videoShow: true,
      currentVideoIndex: 0,
      t: null
    }
  },
  computed: {
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.getVideoLiat()
    },
    // 获取视频列表
    getVideoLiat () {
      let params = {
        deptid: this.departId,
        type: this.objectType ? this.objectType : '',
        onlinestatus: 0
      }
      this.$http.getVideoEquipmentAllList(params).then(res => {
        if (res.code === 200) {
          let arr = []
          res.result.vioList.forEach(item => {
            item.details.forEach(items => {
              let obj = {}
              obj.eqptype = item.eqptype
              obj.equipcode = item.equipcode
              obj.channelid = items.channelid
              arr.push(obj)
            })
          })
          this.videoAllArr = arr
          this.playVideo()
        }
      })
    },
    reloadVideo () {
      clearInterval(this.t)
      this.currentVideoIndex += 1;
      if (this.videoShow) {
        this.video = this.videoAllArr.slice(1 + this.currentVideoIndex, 2 + this.currentVideoIndex)
      } else {
        this.videoPrepare = this.videoAllArr.slice(1 + this.currentVideoIndex, 2 + this.currentVideoIndex)
      }
      this.videoShow = !this.videoShow;

      if (this.currentVideoIndex >= this.videoAllArr.length - 2) {
        this.currentVideoIndex = 0;
      }
      this.t = setInterval(() => {
        this.currentVideoIndex += 1;
        if (this.videoShow) {
          this.video = this.videoAllArr.slice(1 + this.currentVideoIndex, 2 + this.currentVideoIndex)
        } else {
          this.videoPrepare = this.videoAllArr.slice(1 + this.currentVideoIndex, 2 + this.currentVideoIndex)
        }
        this.videoShow = !this.videoShow;

        if (this.currentVideoIndex >= this.videoAllArr.length - 2) {
          this.currentVideoIndex = 0;
        }
      }, 10000);
    },
    playVideo () {
      this.currentVideoIndex = Math.round(Math.random() * (this.videoAllArr.length - 2));
      this.video = this.videoAllArr.slice(this.currentVideoIndex, 1 + this.currentVideoIndex)
      this.videoPrepare = this.videoAllArr.slice(1 + this.currentVideoIndex, 2 + this.currentVideoIndex)
      this.t = setInterval(() => {
        this.currentVideoIndex += 1;
        if (this.videoShow) {
          this.video = this.videoAllArr.slice(1 + this.currentVideoIndex, 2 + this.currentVideoIndex)
        } else {
          this.videoPrepare = this.videoAllArr.slice(1 + this.currentVideoIndex, 2 + this.currentVideoIndex)
        }
        this.videoShow = !this.videoShow;

        if (this.currentVideoIndex >= this.videoAllArr.length - 2) {
          this.currentVideoIndex = 0;
        }
      }, 10000);
    },
  },
}
</script>

<style lang='scss' scoped>
.video {
  background: url("../../../assets/screen/video_bg.png");
  background-size: 100% 100%;
  padding: 0.3472vw 0.6944vw;
  padding-right: 0.8681vw;
  padding-bottom: 0;
  .title-color {
    padding-top: 0.7vw;
    padding-left: 1.5625vw;
    font-size: 0.6597vw;
    .title-img {
      width: 1.2153vw;
      margin-right: 1.7361vw;
      margin-top: -0.1736vw;
    }
  }
  .item-cont {
    height: 12.1528vw;
    display: flex;
    flex-wrap: wrap;
    margin-top: 0.6944vw;
    margin-left: 0.3472vw;
    div {
      width: 100%;
      height: 100%;
      // width: 140px;
      // height: 110px;
      // background: #fff;
      // margin-right: 15px;
      // margin-bottom: 30px;
    }
    // div:nth-child(3n) {
    //   margin-right: 0;
    // }
  }
}
</style>  