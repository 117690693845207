<template>
  <div class="map flex1">
    <img src="../../../assets/screen/map.png"
         class="map-img">
    <div style="z-index:1;">
      <Echarts height="40.191vw"
               :options="mapOptions"
               ref="mapEchartsRef"></Echarts>
    </div>
    <div class="map-left">
      当前：奎文区
    </div>
    <div class="map-right pang-family">
      <p>车辆行驶里程：</p>
      <p>{{mileageData.carMileageSum || 0}}公里</p>
      <p>人员行驶里程：</p>
      <p>{{mileageData.employeeMileageSum || 0}}公里</p>
    </div>
  </div>
</template>

<script>
import bus from '@/utils/bus'
import Echarts from '@/components/common/Echarts';
import * as echarts from 'echarts'
import { kwJson, kwJson1, kwJson2, kwDetailJson } from '@/utils/echartsMap'
import { mapPollingTime } from './largeScreenConfig'
export default {
  components: { Echarts },
  data () {
    return {
      mapOptions: {
        animation: false,
        progressiveThreshold: 3,    // 用于渲染多少数据后开始加载显示图案
        grid: {},
        geo: {
          map: 'kw',
          //"center": [0, 12],
          aspectScale: 0.8,   // 长宽比
          // x y 
          layoutCenter: ['50.4%', '50.1%'],
          // layoutSize: '100%',
          // silent: true,
          roam: false,    // 是否开启鼠标缩放和平移漫游
          z: 10,
          itemStyle: {
            normal: {
              // areaColor: 'rgba(0, 30, 62, .8)',
              shadowColor: 'rgba(64, 64, 64, .5)',
              shadowBlur: 0,
              shadowOffsetX: 5,
              shadowOffsetY: 5,
              borderColor: 'rgba(240, 242, 245, .4)',
              borderWidth: 0.5,
              areaColor: {
                image: null,
                repeat: 'repeat',
              },
            },
            emphasis: {
              shadowColor: 'rgba(144, 152, 158, .3)',
              shadowBlur: 0,
              shadowOffsetX: 5,
              shadowOffsetY: 5,
              borderColor: 'rgba(240, 242, 245, .4)',
              borderWidth: 0.5,
              areaColor: {
                image: null,
                repeat: 'repeat',
              },
              label: {
                show: false,
              },
            }
          },
          // label: { //标签样式设置
          //   normal: {
          //     show: true,
          //     fontSize: "100%",
          //     formatter: function (params) {
          //       return params.name;
          //     },
          //     color: '#FFFFFF',
          //   },
          // }
        },
        series: [
          {
            type: 'lines',
            coordinateSystem: 'geo',
            polyline: true,
            data: [],
            silent: true,
            lineStyle: {
              color: 'rgba(240, 242, 245, .4)',
              opacity: 1,
              width: 2,
              shadowColor: 'rgba(240, 242, 245, .4)',
              shadowBlur: 5,
              // shadowOffsetY: 2
            },
            progressiveThreshold: 500,
            progressive: 200,
            z: 900
          },
          // 上内容
          {
            type: 'scatter',
            coordinateSystem: 'geo',
            label: {
              normal: {
                show: true,
                width: 350,
                // height: 35,
                formatter: function (params) {
                  let levelName = params.data.levelName
                  let planName = params.data.planName
                  let text = `{left|${levelName}}{right|${planName}}`
                  return text
                },
                // offset: [0, -10],
                // position: 'inside',
                position: 'bottom',
                backgroundColor: '#FF0D0D',
                borderWidth: 1,
                borderColor: '#FF0D0D',
                rich: {
                  left: {
                    padding: [10, 0, 10, 10],
                    color: '#fff',
                    fontSize: 14,
                    fontWeight: 400,
                    align: 'left',
                  },
                  right: {
                    padding: [10, 10, 10, 0],
                    color: '#fff',
                    fontSize: 14,
                    fontWeight: 400,
                    align: 'right',
                  },
                },
              },
              emphasis: {
                show: false,
              },
            },
            itemStyle: {
              color: '#ff0000',
            },
            z: 999,
            data: null,
          },
          // 下内容
          {
            type: 'scatter',
            coordinateSystem: 'geo',
            label: {
              normal: {
                show: true,
                width: 350,
                formatter: function (params) {
                  let arr = params.data.arr
                  let text = `{left|${arr}}`
                  return text
                },
                offset: [0, 35],
                position: 'bottom',
                backgroundColor: 'rgba(0,0,0,1)',
                borderWidth: 1,
                borderColor: '#FF0D0D',
                rich: {
                  left: {
                    padding: [0, 10, 0, 10],
                    color: '#fff',
                    fontSize: 14,
                    lineHeight: 30,
                  },
                },
              },
              emphasis: {
                show: true,
              },
            },
            itemStyle: {
              color: '#ff0000',
            },
            symbol: null,
            z: 998,
            data: null,
          },
          // 地图
          {
            type: 'map',
            label: {
              normal: {
                show: false,
                textStyle: {
                  color: '#fff',
                },
              },
              emphasis: {
                textStyle: {
                  color: '#fff',
                },
              },
            },
            itemStyle: {
              normal: {
                borderColor: 'rgba(255, 255, 255, .5)',
                borderWidth: 2,
                areaColor: 'rgba(0, 30, 62, .1)',
                // areaColor: {
                //   image: null,
                //   repeat: 'repeat',
                // },
                shadowColor: 'rgba(0, 255, 255, 0.7)',
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowOffsetY: 0,
              },
              emphasis: {
                areaColor: {
                  // image: domImgHover,
                  repeat: 'repeat',
                },
                borderColor: 'rgba(255, 255, 255, 1)',
                borderWidth: 1,
                shadowColor: 'rgba(0, 255, 255, 0.7)',
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowOffsetY: 1,
                label: {
                  show: false,
                },
              },
            },
            zoom: 1,
            roam: false,
            map: 'kw',
            z: 11
          },
          // 上图片
          {
            type: 'scatter',
            coordinateSystem: 'geo',
            label: {
              normal: {
                show: true,
                width: 365,
                height: 18,
                formatter: ' ',
                color: '#fff',
                offset: [0, -8],
                position: 'bottom',
                backgroundColor: {
                  image: null,
                },
                rich: {
                  left: {
                    color: '#fff',
                    fontSize: 14,
                  },
                },
              },
              emphasis: {
                show: true,
              },
            },
            itemStyle: {
              color: '#00FFF6',
            },
            symbol: null,
            z: 998,
            data: null,
          },
          // 下图片
          {
            type: 'scatter',
            coordinateSystem: 'geo',
            label: {
              normal: {
                show: true,
                width: 365,
                height: 18,
                formatter: ' ',
                color: '#fff',
                offset: null,
                // 1行 55 2行 85 3行 115
                position: 'bottom',
                backgroundColor: {
                  image: null,
                },
                rich: {
                  left: {
                    color: '#fff',
                    fontSize: 14,
                  },
                },
              },
              emphasis: {
                show: true,
              },
            },
            itemStyle: {
              color: '#00FFF6',
            },
            symbol: null,
            z: 998,
            data: null,
          },
          // 高亮的线
          {
            type: 'lines',
            coordinateSystem: 'geo',
            polyline: true,
            data: [],
            silent: true,
            lineStyle: {
              color: '#ff0000',
              opacity: 1,
              width: 2,
            },
            progressiveThreshold: 500,
            progressive: 200,
            z: 999
          },
        ]
      },
      mileageData: {},
      img: require('../../../assets/demo/echarts_demo.png'),
      img1: require('../../../assets/demo/echarts_demo2.png'),
      img2: require('../../../assets/demo/echarts_bg.png'),
      highStreetTop: require('../../../assets/screen/high-street-top.png'),
      highStreetBtoom: require('../../../assets/screen/high-street-bottom.png'),
      collectorStreetsTop: require('../../../assets/screen/collector-streets-top.png'),
      collectorStreetsBottom: require('../../../assets/screen/collector-streets-bottom.png'),
      cundyTop: require('../../../assets/screen/cundy-top.png'),
      cundyBottom: require('../../../assets/screen/cundy-bottom.png'),
      gridArr: [],
      planIndex: 0
    };
  },
  mounted () {
    this.init()
  },
  destroyed () {
    bus.$off('resizeWidth')
  },
  methods: {
    init () {
      echarts.registerMap('kw', kwJson2)
      bus.$on("resizeWidth", data => {
        this.resizeWidth(data)
      })
      this.getMapData()
      this.getMileageData()
    },
    getMapData () {
      this.$http.getMapGuidepostList({ levelId: '' }).then((res) => {
        if (res.code === 200) {
          let data = res.result
          let arr = data.map(item => {
            return JSON.parse(item.lnglat)
          })
          arr = arr.map(item => {
            item = item.map(items => {
              let itemArr = []
              itemArr.push(items.lng)
              itemArr.push(items.lat)
              return itemArr
            })
            return item
          });
          this.gridArr = res.result
          this.mapOptions.series[0].data = arr


          // 地图背景图片
          var domImg = document.createElement('img');
          domImg.style.height = domImg.height = domImg.width = domImg.style.width = '8px';
          domImg.src = this.img2
          this.mapOptions.geo.itemStyle.normal.areaColor.image = domImg
          this.mapOptions.geo.itemStyle.emphasis.areaColor.image = domImg
          // this.mapOptions.series[3].itemStyle.normal.areaColor.image = this.domImg
          // this.$refs.mapEchartsRef.init()

          this.getPlanList()
          setInterval(() => {
            this.getPlanList()
          }, mapPollingTime);
        }
      })
    },
    getMileageData () {
      this.$http.getScreenMileageData().then((res) => {
        this.mileageData = res.result
      })
    },
    getPlanList () {
      // 作业类型：2机械清扫；3垃圾清运；4垃圾转运
      // 已完成3 进行中1 未完成2 未开始0
      // let scatterArr = [
      //   {
      //     value: [119.11553, 36.71392],
      //     arr: [
      //       {
      //         workType: '道路保洁',
      //         planName: '计划名称',
      //         status: 1,
      //       },
      //       {
      //         workType: '道路保洁',
      //         planName: '计划名称计划名称计划名称计划名称计划名称计划名称计划名称计划名称计划名称计划名称计划名称',
      //         status: 1,
      //       },
      //     ]
      //   }
      // ]
      // this.mapOptions.series[1].data = scatterArr
      // this.mapOptions.series[2].data = scatterArr
      // this.$refs.mapEchartsRef.init()
      // return
      let index = Math.round(Math.random() * (this.gridArr.length - 1))
      let params = {
        gridId: this.gridArr[index].gridId
        // gridId: 'd1717ce1b317655c413e07b6149cc1a9'
      }
      this.$http.getScreenMapPlanList(params).then((res) => {
        let scatterArr = res.result.rptCarWorkVoList.concat(res.result.rptEmployeeWorkVoList)
        if (scatterArr.length == 0) {
          // if (this.planIndex >= this.gridArr.length) {
          //   this.planIndex == 0
          // } else {
          //   this.planIndex++
          // }
          this.getPlanList()
          return
        }
        scatterArr = scatterArr.map(item => {
          item.lngLat = JSON.parse(item.lngLat)
          let itemArr = []
          let minLatItem = item.lngLat[0]
          item.lngLat.forEach(items => {
            let item = []
            item.push(items.lng)
            item.push(items.lat)
            itemArr.push(item)
            if (items.lat < minLatItem.lat) {
              minLatItem = items
            }
            // return itemArr
          })
          item.value = [minLatItem.lng, minLatItem.lat]
          item.lngLat = itemArr

          switch (item.workType) {
            case 2:
              item.workType = '机械清扫'
              break;
            case 3:
              item.workType = '垃圾清运'
              break;
            case 4:
              item.workType = '垃圾转运'
              break;
            default:
              item.workType = '道路保洁'
              break;
          }

          switch (item.status) {
            case 0:
              item.status = '未开始'
              break;
            case 1:
              item.status = '进行中'
              break;
            case 2:
              item.status = '未完成'
              break;
            case 3:
              item.status = '已完成'
              break;
            default:
              break;
          }
          return item
        })
        // 第二项 最小的 是南边
        let newArr = [{
          value: scatterArr[0].value,
          highlightLine: [scatterArr[0].lngLat],
          levelName: scatterArr[0].levelName,
          planName: scatterArr[0].planName,
          arr: scatterArr.map(item => {
            let str = `${item.workType}：${item.planName}(${item.shiftName})-${item.status}`
            if (str.length > 25) {
              str = str.slice(0, 25) + '\n' + str.slice(25)
            }
            return str
          }).join('\n')
        }]
        let contLength = newArr[0].arr.split('\n').length - 1
        let contHthghr = 55 + 30 * contLength
        this.mapOptions.series[5].label.normal.offset = [0, contHthghr]
        if (newArr[0].levelName == '主干道 ') {
          this.mapOptions.series[1].label.normal.backgroundColor = '#FF0D0D'
          this.mapOptions.series[1].label.normal.borderColor = '#FF0D0D'
          this.mapOptions.series[1].itemStyle.color = '#FF0D0D'
          this.mapOptions.series[2].label.normal.borderColor = '#FF0D0D'
          this.mapOptions.series[2].itemStyle.color = '#FF0D0D'
          this.mapOptions.series[4].label.normal.backgroundColor.image = this.highStreetTop
          this.mapOptions.series[5].label.normal.backgroundColor.image = this.highStreetBtoom
          this.mapOptions.series[6].lineStyle.color = '#FF0D0D'
        } else if (newArr[0].levelName == '次干道 ') {
          this.mapOptions.series[1].label.normal.backgroundColor = '#FF6600'
          this.mapOptions.series[1].label.normal.borderColor = '#FF6600'
          this.mapOptions.series[1].itemStyle.color = '#FF6600'
          this.mapOptions.series[2].label.normal.borderColor = '#FF6600'
          this.mapOptions.series[2].itemStyle.color = '#FF6600'
          this.mapOptions.series[4].label.normal.backgroundColor.image = this.collectorStreetsTop
          this.mapOptions.series[5].label.normal.backgroundColor.image = this.collectorStreetsBottom
          this.mapOptions.series[6].lineStyle.color = '#FF6600'
        } else if (newArr[0].levelName == '小巷道 ') {
          this.mapOptions.series[1].label.normal.backgroundColor = '#47F4FF'
          this.mapOptions.series[1].label.normal.borderColor = '#47F4FF'
          this.mapOptions.series[1].itemStyle.color = '#47F4FF'
          this.mapOptions.series[2].label.normal.borderColor = '#47F4FF'
          this.mapOptions.series[2].itemStyle.color = '#47F4FF'
          this.mapOptions.series[4].label.normal.backgroundColor.image = this.cundyTop
          this.mapOptions.series[5].label.normal.backgroundColor.image = this.cundyBottom
          this.mapOptions.series[6].lineStyle.color = '#47F4FF'
        }
        this.mapOptions.series[1].data = newArr
        this.mapOptions.series[2].data = newArr
        // 上图片
        this.mapOptions.series[4].data = newArr
        // 下图片
        this.mapOptions.series[5].data = newArr
        // 当前线高亮
        this.mapOptions.series[6].data = newArr[0].highlightLine
        this.$refs.mapEchartsRef.init()
        // if (this.planIndex >= this.gridArr.length) {
        //   this.planIndex == 0
        // } else {
        //   this.planIndex++
        // }
      })
    },
    resizeWidth (data) {
      switch (data.status) {
        case 0:
          // this.mapOptions.geo.label.normal.fontSize = 14
          this.mapOptions.series[0].pointSize = 20
          break;
        case 1:
          // this.mapOptions.geo.label.normal.fontSize = 22
          this.mapOptions.series[0].pointSize = 30
          break;
        case 2:
          // this.mapOptions.geo.label.normal.fontSize = 35
          this.mapOptions.series[0].pointSize = 50
          break;
        default:
          break;
      }
      this.$refs.mapEchartsRef.resize()
    },
    // 随机数  舍弃
    randomData () {
      return Math.round(Math.random() * 3);
    },
  },
}
</script>

<style lang='scss' scoped>
.map {
  background: no-repeat;
  background-image: url("../../../assets/screen/map_bg.png");
  background-size: 100% 100%;
  position: relative;
  .map-img {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    filter: grayscale(100%) opacity(30%);
  }
  .map-left {
    position: absolute;
    top: 1.3889vw;
    left: 5.2083vw;
    font-size: 0.7292vw;
    line-height: 0.5903vw;
    color: #c2d7fd;
    font-weight: bold;
    border-bottom: 0.0694vw solid #c7e8fe;
    padding-bottom: 0.1736vw;
  }
  .map-right {
    width: 7.4653vw;
    height: 5.2083vw;
    position: absolute;
    top: 0;
    right: 3.4722vw;
    padding: 0.8681vw;
    background: url("../../../assets/screen/km_bg.png") no-repeat;
    background-size: 100% 100%;
    color: #e8effb;
    font-size: 0.5903vw;
    p:nth-child(2),
    p:nth-child(4) {
      color: #ffcc00;
    }
  }
}
</style>