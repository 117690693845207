<template>
  <div class="work-broadcast">
    <div class="item">
      <div class="title-color flex j-between">
        作业播报
      </div>
      <div class="noData"
           v-if="workBroadcast.length == 0">
        <img src="../../../assets/screen/no_data.png">
        暂无数据
      </div>
      <ul class="rotation"
          ref="workBroadcast"
          v-else>
        <vue-seamless-scroll :data="workBroadcast"
                             :class-option="workOption">
          <li class="flex j-between"
              v-for="(item,index) in workBroadcast"
              :key="index">
            <div class="rotation-left flex">
              <div>{{item.name}}：</div>
              <div class="line2">{{item.content}}</div>
            </div>
            <div>{{formatTime(item.time)}}</div>
          </li>
        </vue-seamless-scroll>
      </ul>
    </div>
  </div>
</template>

<script>
import bus from "@/utils/bus"
import vueSeamlessScroll from 'vue-seamless-scroll'
import { pollingTime } from './largeScreenConfig'
export default {
  components: {
    vueSeamlessScroll,
  },
  data () {
    return {
      workBroadcast: [],
      workOption: {
        limitMoveNum: 5,
        singleHeight: 0,
        waitTime: 15000
      }
    }
  },
  computed: {
    // workOption () {
    //   return {
    //     limitMoveNum: 5,
    //     singleHeight: 35,
    //     waitTime: 15000
    //   }
    // },
  },
  mounted () {
    this.init()
  },
  destroyed () {
    bus.$off('resizeWidth')
  },
  methods: {
    init () {
      bus.$on("resizeWidth", data => {
        this.resizeWidth(data)
      })
      this.getWorkList()
      setInterval(() => {
        this.getWorkList()
      }, pollingTime);
    },
    // 获取作业播报数据
    getWorkList () {
      this.$http.getScreenWorkBroadcast().then((res) => {
        if (res.code == 200) {
          this.workBroadcast = res.result
          this.$nextTick(() => {
            let height = this.$refs.workBroadcast.offsetHeight / 5
            this.workOption.singleHeight = parseInt(height)
          })
        }
      })
    },
    // 作业概况时间 去掉年月日
    formatTime (time) {
      if (time) {
        let arr = time.split(' ')
        let newArr = arr[1].split(':')
        return newArr[0] + ':' + newArr[1]
      } else {
        return ''
      }
    },
    resizeWidth (data) {
      switch (data.status) {
        case 0:
          this.workOption.singleHeight = 35
          break;
        case 1:
          break;
        case 2:
          break;
        default:
          break;
      }
    }
  },
}
</script>

<style lang='scss' scoped>
.work-broadcast {
  background: url("../../../assets/screen/work_broadcast_bg.png");
  background-size: 100% 100%;
  padding: 0.3472vw 0.6944vw;
  padding-right: 0.8681vw;
  padding-bottom: 0;
  .title-color {
    padding-top: 0.6944vw;
    padding-left: 1.3889vw;
    font-size: 0.6597vw;
  }
  .noData {
    img {
      width: 4.3403vw;
      height: 4.3403vw;
      margin-bottom: 0.1736vw;
    }
  }
  .rotation {
    height: 9.2014vw;
    margin-top: 0.6944vw;
    margin-left: 0.1736vw;
    font-size: 0.5903vw;
    overflow: hidden;
    li {
      height: 1.8229vw;
      overflow: hidden;
      .rotation-left {
        div:last-child {
          width: 8.3333vw;
        }
      }
    }
  }
}
</style>  