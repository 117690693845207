<template>
  <div class="facl">
    <div class="title-color flex j-between">
      设施数据
    </div>
    <div class="item-cont">
      <div class="facl-title">
        <span class="cursor-pointer"
              :class="{'facl-title-current':currentIndexFacl==0}"
              @click.stop="onClickFaclTab(0)">公厕</span>
      </div>
      <div class="facl-cont">
        <div class="public-toilet">
          <div>公厕数：{{faclData.facilitiesNum || 0}}</div>
          <ul class="flex a-center j-between m-t-15">
            <li>
              <img src="../../../assets/screen/today_toilet.png">
              今日人流量：{{faclData.pedestrianVolume || 0}}
            </li>
            <li>
              <img src="../../../assets/screen/total_toilet.png">
              本月人流总量：{{faclData.pedestrianVolumeMonth || 0}}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vueSeamlessScroll from 'vue-seamless-scroll'
import { pollingTime } from './largeScreenConfig'
export default {
  components: {
    vueSeamlessScroll,
  },
  data () {
    return {
      currentIndexFacl: 0,
      faclData: {}
    }
  },
  computed: {
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.getFaclData()
      setInterval(() => {
        this.getFaclData()
      }, pollingTime);
    },
    // 获取设施数据
    getFaclData () {
      this.$http.getScreenFaclData({}).then((res) => {
        if (res.code == 200) {
          this.faclData = res.result
        }
      })
    },
    // 作业概况时间 去掉年月日
    formatTime (time) {
      if (time) {
        let arr = time.split(' ')
        let newArr = arr[1].split(':')
        return newArr[0] + ':' + newArr[1]
      } else {
        return ''
      }
    },
  },
}
</script>

<style lang='scss' scoped>
.facl {
  background: url("../../../assets/screen/facl_bg.png");
  background-size: 100% 100%;
  padding: 0.3472vw 0.6944vw;
  padding-bottom: 0;
  .title-color {
    padding-top: 0.6076vw;
    padding-left: 1.7361vw;
    font-size: 0.6597vw;
  }
  .item-cont {
    margin-top: 0.8681vw;
    margin-left: 0.3472vw;
    .facl-title {
      font-size: 0.5903vw;
      span {
        padding-bottom: 0.0347vw;
        margin-right: 0.1736vw;
      }
      .facl-title-current {
        border-bottom: 0.0521vw solid #00cbff;
      }
    }
    .facl-cont {
      margin-top: -0.8681vw;
      font-size: 0.5903vw;
      .public-toilet {
        text-align: right;
        div {
          margin-bottom: 1.0417vw;
        }
        ul {
          margin: 0 0.6944vw;
          li {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            img {
              width: 2.6042vw;
              height: 2.6042vw;
              margin-bottom: 1.0417vw;
            }
          }
        }
      }
    }
  }
}
</style>  