<template>
  <div class="person">
    <div class="title-color flex j-between">
      人员作业
    </div>
    <div class="person-title flex flex-wrap">
      <div class="w-p-50 bold person-title-top">人员总量：<span>{{personData.total || 0}}</span></div>
      <ul class="w-p-50 flex">
        <li>在线：<span>{{personData.onlineCount || 0}}</span></li>
        <li>出勤：<span>{{personData.chuqinCount || 0}}</span></li>
      </ul>
      <div class="fs-30 person-title-bottom">今日作业：<span>计划数{{personWorkData.workPlanCount || 0}}</span></div>
    </div>
    <div class="flex">
      <Echarts width="13.8889vw"
               height="10.4167vw"
               :options="personOptions"
               :notMerge="true"
               ref="personEchartsRef"
               v-show="personEchartsVisible"></Echarts>
      <div class="noData"
           v-show="!personEchartsVisible">
        <img src="../../../assets/screen/no_data.png">
        暂无数据
      </div>
    </div>
  </div>
</template>

<script>
import bus from '@/utils/bus'
import Echarts from '@/components/common/Echarts';
import { pollingTime } from './largeScreenConfig'
export default {
  components: { Echarts },
  data () {
    return {
      // 图表相关
      personAngle: 0,
      carAngle: 0,
      img: require('../../../assets/screen/pieBg.png'),
      color: ['#00FF54', '#FF7900', '#28F2E6'],
      // 人图表
      personSeriesOption: [
        {
          name: '',
          type: 'pie',
          clockWise: false,   //饼图的扇区是否是顺时针排布。
          radius: ['50%', '70%'],
          hoverAnimation: false,
          itemStyle: {
            normal: {
              label: {
                show: true,
                position: 'outside',    // 饼图扇区外侧，通过视觉引导线连到相应的扇区。
                color: '#ddd',
                fontSize: "100%",
                formatter: function (params) {
                  if (params.name != '') {
                    return params.name + "：" + params.value;
                  } else {
                    return ''
                  }
                },
              },
              labelLine: {
                length: "6%",
                length2: "5%",
                show: true,
                color: '#fff',
                lineStyle: {
                  width: 2
                }
              },
            },
          },
          data: this.personEchartsData,
        },
        // 紫色
        {
          name: 'ring5',
          type: 'custom',     // 自定义系列
          coordinateSystem: 'none',   // 该系列使用的坐标系
          renderItem: (params, api) => {
            // console.log(api.getWidth());
            return {
              type: 'arc',    // 圆弧
              shape: {
                cx: api.getWidth() / 2,   // 图形元素的中心在父节点坐标系（以父节点左上角为原点）中的横坐标值。
                cy: api.getHeight() / 2,  // 图形元素的中心在父节点坐标系（以父节点左上角为原点）中的纵坐标值。
                r: Math.min(api.getWidth(), api.getHeight()) / 2.6,   // 外半径
                startAngle: ((0 + this.personAngle) * Math.PI) / 180,   // 开始弧度。
                endAngle: ((90 + this.personAngle) * Math.PI) / 180,    // 结束弧度。
              },
              style: {
                stroke: '#00FEFF',    // 笔画颜色。
                fill: 'transparent',  // 填充色
                lineWidth: 1.5,       // 笔画宽度
              },
              silent: true,     // 图形是否不响应和触发鼠标事件，默认为 false，即响应和触发鼠标事件。
            };
          },
          data: [0],
        },
        {
          name: 'ring5', //紫点
          type: 'custom',
          coordinateSystem: 'none',
          renderItem: (params, api) => {
            let x0 = api.getWidth() / 2;
            let y0 = api.getHeight() / 2;
            let r = Math.min(api.getWidth(), api.getHeight()) / 2.6;
            let point = this.getCirlPoint(x0, y0, r, 90 + this.personAngle);
            return {
              type: 'circle',    // 圆
              shape: {
                cx: point.x,
                cy: point.y,
                r: 4,
              },
              style: {
                stroke: '#00FEFF', //绿
                fill: '#00FEFF',
              },
              silent: true,
            };
          },
          data: [0],
        },
        // 蓝色
        {
          name: 'ring5',
          type: 'custom',
          coordinateSystem: 'none',
          renderItem: (params, api) => {
            return {
              type: 'arc',
              shape: {
                cx: api.getWidth() / 2,
                cy: api.getHeight() / 2,
                r: Math.min(api.getWidth(), api.getHeight()) / 2.6,
                startAngle: ((180 + this.personAngle) * Math.PI) / 180,
                endAngle: ((270 + this.personAngle) * Math.PI) / 180,
              },
              style: {
                stroke: '#00FEFF',
                fill: 'transparent',
                lineWidth: 1.5,
              },
              silent: true,
            };
          },
          data: [0],
        },
        {
          name: 'ring5', // 蓝色
          type: 'custom',
          coordinateSystem: 'none',
          renderItem: (params, api) => {
            let x0 = api.getWidth() / 2;
            let y0 = api.getHeight() / 2;
            let r = Math.min(api.getWidth(), api.getHeight()) / 2.6;
            let point = this.getCirlPoint(x0, y0, r, 180 + this.personAngle);
            return {
              type: 'circle',
              shape: {
                cx: point.x,
                cy: point.y,
                r: 4,
              },
              style: {
                stroke: '#00FEFF', //绿
                fill: '#00FEFF',
              },
              silent: true,
            };
          },
          data: [0],
        },
        {
          name: 'ring5',
          type: 'custom',
          coordinateSystem: 'none',
          renderItem: (params, api) => {
            return {
              type: 'arc',
              shape: {
                cx: api.getWidth() / 2,
                cy: api.getHeight() / 2,
                r: Math.min(api.getWidth(), api.getHeight()) / 2.8,
                startAngle: ((270 + -this.personAngle) * Math.PI) / 180,
                endAngle: ((40 + -this.personAngle) * Math.PI) / 180,
              },
              style: {
                stroke: '#00FEFF',
                fill: 'transparent',
                lineWidth: 1.5,
              },
              silent: true,
            };
          },
          data: [0],
        },
        // 橘色
        {
          name: 'ring5',
          type: 'custom',
          coordinateSystem: 'none',
          renderItem: (params, api) => {
            return {
              type: 'arc',
              shape: {
                cx: api.getWidth() / 2,
                cy: api.getHeight() / 2,
                r: Math.min(api.getWidth(), api.getHeight()) / 2.8,
                startAngle: ((90 + -this.personAngle) * Math.PI) / 180,
                endAngle: ((220 + -this.personAngle) * Math.PI) / 180,
              },
              style: {
                stroke: '#00FEFF',
                fill: 'transparent',
                lineWidth: 1.5,
              },
              silent: true,
            };
          },
          data: [0],
        },
        {
          name: 'ring5',
          type: 'custom',
          coordinateSystem: 'none',
          renderItem: (params, api) => {
            let x0 = api.getWidth() / 2;
            let y0 = api.getHeight() / 2;
            let r = Math.min(api.getWidth(), api.getHeight()) / 2.8;
            let point = this.getCirlPoint(x0, y0, r, 90 + -this.personAngle);
            return {
              type: 'circle',
              shape: {
                cx: point.x,
                cy: point.y,
                r: 4,
              },
              style: {
                stroke: '#00FEFF', //粉
                fill: '#00FEFF',
              },
              silent: true,
            };
          },
          data: [0],
        },
        {
          name: 'ring5', //绿点
          type: 'custom',
          coordinateSystem: 'none',
          renderItem: (params, api) => {
            let x0 = api.getWidth() / 2;
            let y0 = api.getHeight() / 2;
            let r = Math.min(api.getWidth(), api.getHeight()) / 2.8;
            let point = this.getCirlPoint(x0, y0, r, 270 + -this.personAngle);
            return {
              type: 'circle',
              shape: {
                cx: point.x,
                cy: point.y,
                r: 4,
              },
              style: {
                stroke: '#00FEFF', //绿
                fill: '#00FEFF',
              },
              silent: true,
            };
          },
          data: [0],
        },
      ],
      personDimension: [
        {
          name: '在线',
          value: 0,
        },
        {
          name: '在岗',
          value: 0,
        },
        {
          name: '出勤',
          value: 0,
        },
      ],
      personOptions: {
        color: this.color,   // 颜色
        graphic: {    // 原生图形元素组件。  中间黄色图片
          elements: [
            {
              type: 'image',
              z: 9,   // z 方向的高度，决定层叠关系。
              style: {
                image: this.img,
                width: 70,
                height: 70,
              },
              left: 'center',
              top: 'center',
              position: [100, 100],
            },
          ],
        },
        tooltip: {
          show: false,
        },
        toolbox: {    // 工具栏
          show: false,
        },
        series: this.personSeriesOption,
      },
      personEchartsData: [],
      personTimerId: null,
      personEchartsVisible: true,
      personWorkData: {},
      // 人数据
      personData: {},
    };
  },
  mounted () {
    this.init()
  },
  destroyed () {
    bus.$off('resizeWidth')
  },
  methods: {
    init () {
      bus.$on("resizeWidth", data => {
        this.resizeWidth(data)
      })
      // 人
      this.getPersonData()
      this.getPersonWork()
      setInterval(() => {
        this.getPersonData()
        this.getPersonWork()
      }, pollingTime);
      // this.personLineAnimation()
    },
    // 人的图表
    getPersonEcharts () {
      this.personEchartsData = []
      for (var i = 0; i < this.personDimension.length; i++) {
        this.personEchartsData.push(
          {
            value: this.personDimension[i].value,
            name: this.personDimension[i].name,
            itemStyle: {
              normal: {
                borderWidth: 5,
                shadowBlur: 20,
                borderColor: this.color[i],
                shadowColor: this.color[i],
              },
            },
          },
          // {
          //   value: 2,
          //   name: '',
          //   itemStyle: {
          //     normal: {
          //       label: {
          //         show: false,
          //       },
          //       labelLine: {
          //         show: false,
          //       },
          //       color: 'rgba(0, 0, 0, 0)',
          //       borderColor: 'rgba(0, 0, 0, 0)',
          //       borderWidth: 0,
          //     },
          //   },
          // }
        );
      }
      this.personOptions.color = this.color
      this.personSeriesOption[0].data = this.personEchartsData
      this.personOptions.series = this.personSeriesOption
      this.personOptions.graphic.elements[0].style.image = this.img
      if (this.personTimerId) {
        clearInterval(this.personTimerId);
      }
      this.personTimerId = setInterval(() => {
        //用setInterval做动画感觉有问题
        this.personDraw()
      }, 100);
    },
    personDraw () {
      this.personAngle = this.personAngle + 3;
      this.$refs.personEchartsRef.init()
    },
    // 人的数据
    getPersonData () {
      this.$http.getScreenPersonData().then((res) => {
        this.personData = res.result
      })
    },
    getPersonWork () {
      this.$http.getScreenPersonOnline().then(res => {
        this.personWorkData = res.result
        this.personDimension[0].value = this.personWorkData.onlineStatusCount
        this.personDimension[1].value = this.personWorkData.onGuardStatusCount
        this.personDimension[2].value = this.personWorkData.attendanceCount
        if (this.personDimension[0].value > 0 || this.personDimension[1].value > 0 || this.personDimension[2].value > 0) {
          this.personEchartsVisible = true
        } else {
          this.personEchartsVisible = false
        }
        this.getPersonEcharts()
      })
    },
    personLineAnimation () {
      let arr = this.$refs.personLineAnimationRef
      let i = 0
      setInterval(() => {
        if (arr[i]) {
          arr[i].style.opacity = 1
        }
        if (arr[i - 1]) {
          arr[i - 1].style.opacity = 1
        }
        if (arr[i - 2]) {
          arr[i - 2].style.opacity = 1
        }
        if (arr[i - 3]) {
          arr[i - 3].style.opacity = 1
        }
        if (arr[i - 4]) {
          arr[i - 4].style.opacity = 1
        }
        if (arr[i - 5]) {
          arr[i - 5].style.opacity = 1
        }
        arr.forEach((item, index) => {
          if (index != i && index != i - 1 && index != i - 2 && index != i - 3 && index != i - 4 && index != i - 5) {
            arr[index].style.opacity = .5
          }
        })
        if (i == arr.length + 1) {
          i = 0
        } else {
          i++
        }
      }, 50);
    },
    getCirlPoint (x0, y0, r, angle) {
      let x1 = x0 + r * Math.cos((angle * Math.PI) / 180);
      let y1 = y0 + r * Math.sin((angle * Math.PI) / 180);
      return {
        x: x1,
        y: y1,
      };
    },
    resizeWidth (data) {
      switch (data.status) {
        case 0:
          this.personSeriesOption[0].itemStyle.normal.label.fontSize = 12
          this.personOptions.graphic.elements[0].style.width = 70
          this.personOptions.graphic.elements[0].style.height = 70
          break;
        case 1:
          this.personSeriesOption[0].itemStyle.normal.label.fontSize = 18
          this.personOptions.graphic.elements[0].style.width = 100
          this.personOptions.graphic.elements[0].style.height = 100
          break;
        case 2:
          this.personSeriesOption[0].itemStyle.normal.label.fontSize = 30
          this.personOptions.graphic.elements[0].style.width = 140
          this.personOptions.graphic.elements[0].style.height = 140
          break;
        default:
          break;
      }
      this.$refs.personEchartsRef.resize()
    }
  },
}
</script>

<style lang='scss' scoped>
.person {
  background: url("../../../assets/screen/person_bg.png") no-repeat;
  background-size: 100% 100%;
  position: relative;
  padding: 0.3472vw 0.6944vw;
  padding-right: 0.8681vw;
  padding-bottom: 0;
  .title-color {
    padding-top: 0.6944vw;
    padding-left: 1.3889vw;
    font-size: 0.6597vw;
  }
  .person-title {
    margin-top: 1.3889vw;
    margin-left: 0.3472vw;
    .person-title-top {
      font-size: 0.5903vw;
    }
    ul {
      font-size: 0.5208vw;
      li:first-child {
        margin-right: 0.1736vw;
      }
    }
    .person-title-bottom {
      margin-top: 1.0417vw;
      font-size: 0.5208vw;
    }
  }
  .person-line {
    height: 5.5903vw;
    position: absolute;
    top: 0;
    left: -0.2604vw;
    div {
      width: 0.1736vw;
      height: 0.1736vw;
      margin-bottom: 0.1736vw;
      background: #b7cffc;
      opacity: 0.5;
    }
  }
  .noData {
    margin-left: -1.3889vw;
    img {
      width: 6.9444vw;
      height: 6.9444vw;
      margin-top: 1.0417vw;
      margin-bottom: 0.1736vw;
    }
  }
}
</style>  