<template>
  <div class="car">
    <!-- <div class="person-line">
      <div v-for="(item,index) in 35"
           :key="index"
           ref="personLineAnimationRef"></div>
    </div> -->
    <div class="title-color flex j-between">
      车辆作业
    </div>
    <div class=" flex flex-wrap car-title">
      <div class="w-p-50 bold car-title-top">车辆总量：<span>{{carData.total || 0}}</span></div>
      <ul class="w-p-50 flex">
        <li class="">在线：<span>{{carData.onlineCount || 0}}</span></li>
        <li>出勤：<span>{{carData.chuQinCount || 0}}</span></li>
      </ul>
      <div class="w-p-50 car-title-bottom">今日作业：<span>计划数{{carWork.total || 0}}</span></div>
    </div>
    <div class="flex a-center car-echart">
      <Echarts width="15.625vw"
               height="11.4167vw"
               :options="carOptions"
               :notMerge="true"
               ref="carEchartsRef"
               v-show="carEchartsVisible"></Echarts>
      <div class="noData"
           v-show="!carEchartsVisible">
        <img src="../../../assets/screen/no_data.png">
        暂无数据
      </div>
    </div>
  </div>
</template>

<script>
import bus from '@/utils/bus'
import Echarts from '@/components/common/Echarts';
import { pollingTime } from './largeScreenConfig'
export default {
  components: { Echarts },
  data () {
    return {
      // 图表相关
      personAngle: 0,
      carAngle: 0,
      img: require('../../../assets/screen/pieBg.png'),
      color: ['#00FF54', '#FF7900', '#28F2E6'],
      // 车
      carSeriesOption: [
        {
          name: '',
          type: 'pie',
          clockWise: false,   //饼图的扇区是否是顺时针排布。
          radius: ['40%', '60%'],
          hoverAnimation: false,
          itemStyle: {
            normal: {
              label: {
                show: true,
                position: 'outside',    // 饼图扇区外侧，通过视觉引导线连到相应的扇区。
                color: '#ddd',
                fontSize: "60px",
                formatter: function (params) {
                  if (params.name != '') {
                    return params.name + "：" + params.value;
                  } else {
                    return ''
                  }
                },
              },
              labelLine: {
                length: "5%",
                length2: "4%",
                show: true,
                color: '#fff',
                lineStyle: {
                  width: 2
                }
              },
            },
          },
          data: this.carEchartsData,
        },
        // 紫色
        {
          name: 'ring5',
          type: 'custom',     // 自定义系列
          coordinateSystem: 'none',   // 该系列使用的坐标系
          renderItem: (params, api) => {
            // console.log(api.getWidth());
            return {
              type: 'arc',    // 圆弧
              shape: {
                cx: api.getWidth() / 2,   // 图形元素的中心在父节点坐标系（以父节点左上角为原点）中的横坐标值。
                cy: api.getHeight() / 2,  // 图形元素的中心在父节点坐标系（以父节点左上角为原点）中的纵坐标值。
                r: Math.min(api.getWidth(), api.getHeight()) / 2.6,   // 外半径
                startAngle: ((0 + this.carAngle) * Math.PI) / 180,   // 开始弧度。
                endAngle: ((90 + this.carAngle) * Math.PI) / 180,    // 结束弧度。
              },
              style: {
                stroke: '#00FEFF',    // 笔画颜色。
                fill: 'transparent',  // 填充色
                lineWidth: 1.5,       // 笔画宽度
              },
              silent: true,     // 图形是否不响应和触发鼠标事件，默认为 false，即响应和触发鼠标事件。
            };
          },
          data: [0],
        },
        {
          name: 'ring5', //紫点
          type: 'custom',
          coordinateSystem: 'none',
          renderItem: (params, api) => {
            let x0 = api.getWidth() / 2;
            let y0 = api.getHeight() / 2;
            let r = Math.min(api.getWidth(), api.getHeight()) / 2.6;
            let point = this.getCirlPoint(x0, y0, r, 90 + this.carAngle);
            return {
              type: 'circle',    // 圆
              shape: {
                cx: point.x,
                cy: point.y,
                r: 4,
              },
              style: {
                stroke: '#00FEFF', //绿
                fill: '#00FEFF',
              },
              silent: true,
            };
          },
          data: [0],
        },
        // 蓝色
        {
          name: 'ring5',
          type: 'custom',
          coordinateSystem: 'none',
          renderItem: (params, api) => {
            return {
              type: 'arc',
              shape: {
                cx: api.getWidth() / 2,
                cy: api.getHeight() / 2,
                r: Math.min(api.getWidth(), api.getHeight()) / 2.6,
                startAngle: ((180 + this.carAngle) * Math.PI) / 180,
                endAngle: ((270 + this.carAngle) * Math.PI) / 180,
              },
              style: {
                stroke: '#00FEFF',
                fill: 'transparent',
                lineWidth: 1.5,
              },
              silent: true,
            };
          },
          data: [0],
        },
        {
          name: 'ring5', // 蓝色
          type: 'custom',
          coordinateSystem: 'none',
          renderItem: (params, api) => {
            let x0 = api.getWidth() / 2;
            let y0 = api.getHeight() / 2;
            let r = Math.min(api.getWidth(), api.getHeight()) / 2.6;
            let point = this.getCirlPoint(x0, y0, r, 180 + this.carAngle);
            return {
              type: 'circle',
              shape: {
                cx: point.x,
                cy: point.y,
                r: 4,
              },
              style: {
                stroke: '#00FEFF', //绿
                fill: '#00FEFF',
              },
              silent: true,
            };
          },
          data: [0],
        },
        {
          name: 'ring5',
          type: 'custom',
          coordinateSystem: 'none',
          renderItem: (params, api) => {
            return {
              type: 'arc',
              shape: {
                cx: api.getWidth() / 2,
                cy: api.getHeight() / 2,
                r: Math.min(api.getWidth(), api.getHeight()) / 2.8,
                startAngle: ((270 + -this.carAngle) * Math.PI) / 180,
                endAngle: ((40 + -this.carAngle) * Math.PI) / 180,
              },
              style: {
                stroke: '#00FEFF',
                fill: 'transparent',
                lineWidth: 1.5,
              },
              silent: true,
            };
          },
          data: [0],
        },
        // 橘色
        {
          name: 'ring5',
          type: 'custom',
          coordinateSystem: 'none',
          renderItem: (params, api) => {
            return {
              type: 'arc',
              shape: {
                cx: api.getWidth() / 2,
                cy: api.getHeight() / 2,
                r: Math.min(api.getWidth(), api.getHeight()) / 2.8,
                startAngle: ((90 + -this.carAngle) * Math.PI) / 180,
                endAngle: ((220 + -this.carAngle) * Math.PI) / 180,
              },
              style: {
                stroke: '#00FEFF',
                fill: 'transparent',
                lineWidth: 1.5,
              },
              silent: true,
            };
          },
          data: [0],
        },
        {
          name: 'ring5',
          type: 'custom',
          coordinateSystem: 'none',
          renderItem: (params, api) => {
            let x0 = api.getWidth() / 2;
            let y0 = api.getHeight() / 2;
            let r = Math.min(api.getWidth(), api.getHeight()) / 2.8;
            let point = this.getCirlPoint(x0, y0, r, 90 + -this.carAngle);
            return {
              type: 'circle',
              shape: {
                cx: point.x,
                cy: point.y,
                r: 4,
              },
              style: {
                stroke: '#00FEFF', //粉
                fill: '#00FEFF',
              },
              silent: true,
            };
          },
          data: [0],
        },
        {
          name: 'ring5', //绿点
          type: 'custom',
          coordinateSystem: 'none',
          renderItem: (params, api) => {
            let x0 = api.getWidth() / 2;
            let y0 = api.getHeight() / 2;
            let r = Math.min(api.getWidth(), api.getHeight()) / 2.8;
            let point = this.getCirlPoint(x0, y0, r, 270 + -this.carAngle);
            return {
              type: 'circle',
              shape: {
                cx: point.x,
                cy: point.y,
                r: 4,
              },
              style: {
                stroke: '#00FEFF', //绿
                fill: '#00FEFF',
              },
              silent: true,
            };
          },
          data: [0],
        },
      ],
      carDimension: [
        {
          name: '未完成',
          value: 0,
        },
        {
          name: '已完成',
          value: 0,
        },
        {
          name: '进行中',
          value: 0,
        },
      ],
      carOptions: {
        color: this.color,   // 颜色
        graphic: {    // 原生图形元素组件。  中间黄色图片
          elements: [
            {
              type: 'image',
              z: 9,   // z 方向的高度，决定层叠关系。
              style: {
                image: this.img,
                width: 70,
                height: 70,
              },
              left: 'center',
              top: 'center',
              position: [100, 100],
            },
          ],
        },
        tooltip: {
          show: false,
        },
        toolbox: {    // 工具栏
          show: false,
        },
        series: this.carSeriesOption,
      },
      carEchartsData: [],
      carTimerId: null,
      carEchartsVisible: false,
      // 车数据
      carData: {},
      carWork: {}
    };
  },
  mounted () {
    this.init()
  },
  destroyed () {
    bus.$off('resizeWidth')
  },
  methods: {
    init () {
      bus.$on("resizeWidth", data => {
        this.resizeWidth(data)
      })
      // this.personLineAnimation()
      // 车
      this.getCarData()
      this.getCarWork()
      setInterval(() => {
        this.getCarData()
        this.getCarWork()
      }, pollingTime);
    },
    // 车的图表
    getCarEcharts () {
      this.carEchartsData = []
      for (var i = 0; i < this.carDimension.length; i++) {
        this.carEchartsData.push(
          {
            value: this.carDimension[i].value,
            name: this.carDimension[i].name,
            itemStyle: {
              normal: {
                borderWidth: 5,
                shadowBlur: 20,
                borderColor: this.color[i],
                shadowColor: this.color[i],
              },
            },
          },
          // {
          //   value: 2,
          //   name: '',
          //   itemStyle: {
          //     normal: {
          //       label: {
          //         show: false,
          //       },
          //       labelLine: {
          //         show: false,
          //       },
          //       color: 'rgba(0, 0, 0, 0)',
          //       borderColor: 'rgba(0, 0, 0, 0)',
          //       borderWidth: 0,
          //     },
          //   },
          // }
        );
      }
      this.carOptions.color = this.color
      this.carSeriesOption[0].data = this.carEchartsData
      this.carOptions.series = this.carSeriesOption
      this.carOptions.graphic.elements[0].style.image = this.img
      if (this.carTimerId) {
        clearInterval(this.carTimerId);
      }
      this.carTimerId = setInterval(() => {
        //用setInterval做动画感觉有问题
        this.carDraw()
      }, 100);
    },
    carDraw () {
      this.carAngle = this.carAngle + 3;
      this.$refs.carEchartsRef.init()
    },
    // 车的数据
    getCarData () {
      this.$http.getScreenCarData().then((res) => {
        if (res.code == 200) {
          this.carData = res.result
        }
      })
    },
    getCarWork () {
      this.$http.getScreenCarWork().then((res) => {
        this.carWork = res.result
        this.carWork.noFinish = this.carWork.total - this.carWork.finished - this.carWork.working
        this.carDimension[0].value = this.carWork.noFinish
        this.carDimension[1].value = this.carWork.finished
        this.carDimension[2].value = this.carWork.working
        if (this.carDimension[0].value > 0 || this.carDimension[1].value > 0 || this.carDimension[2].value > 0) {
          this.carEchartsVisible = true
        } else {
          this.carEchartsVisible = false
        }
        this.getCarEcharts()
      })
    },
    getCirlPoint (x0, y0, r, angle) {
      let x1 = x0 + r * Math.cos((angle * Math.PI) / 180);
      let y1 = y0 + r * Math.sin((angle * Math.PI) / 180);
      return {
        x: x1,
        y: y1,
      };
    },
    resizeWidth (data) {
      switch (data.status) {
        case 0:
          this.carSeriesOption[0].itemStyle.normal.label.fontSize = 12
          this.carOptions.graphic.elements[0].style.width = 70
          this.carOptions.graphic.elements[0].style.height = 70
          break;
        case 1:
          this.carSeriesOption[0].itemStyle.normal.label.fontSize = 18
          this.carOptions.graphic.elements[0].style.width = 100
          this.carOptions.graphic.elements[0].style.height = 100
          break;
        case 2:
          this.carSeriesOption[0].itemStyle.normal.label.fontSize = 30
          this.carOptions.graphic.elements[0].style.width = 140
          this.carOptions.graphic.elements[0].style.height = 140
          break;
        default:
          break;
      }
      this.$refs.carEchartsRef.resize()
    }
  },
}
</script>

<style lang='scss' scoped>
.car {
  position: relative;
  background: url("../../../assets/screen/car_bg.png") no-repeat;
  background-size: 100% 100%;
  padding: 0.1736vw 0.6944vw;
  padding-right: 0.8681vw;
  padding-bottom: 0;
  position: relative;
  .title-color {
    padding-top: 0.6944vw;
    padding-left: 1.3889vw;
    font-size: 0.6597vw;
  }
  .car-title {
    margin-top: 1.3889vw;
    margin-left: 0.1736vw;
    .car-title-top {
      font-size: 0.5903vw;
    }
    ul {
      font-size: 0.5208vw;
      li:first-child {
        margin-right: 0.1736vw;
      }
    }
    .car-title-bottom {
      margin-top: 1.0417vw;
      font-size: 0.5208vw;
    }
  }
  .car-echart {
    margin-top: 1.0417vw;
    // padding-left: 0.5208vw;
  }
  .person-line {
    height: 5.5903vw;
    position: absolute;
    top: 0;
    left: -0.2604vw;
    div {
      width: 0.1736vw;
      height: 0.1736vw;
      margin-bottom: 0.1736vw;
      background: #b7cffc;
      opacity: 0.5;
    }
  }
  .noData {
    margin-left: -1.3889vw;
    img {
      width: 6.9444vw;
      height: 6.9444vw;
      margin-top: 1.0417vw;
      margin-bottom: 0.1736vw;
    }
  }
}
</style>  