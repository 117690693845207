<template>
  <div class="title flex j-between w-full">
    <div class="left">
      奎文区 {{weather.forecast ? weather.forecast[0].type : ''}} {{weather.wendu}}°C
    </div>
    <div class="title-color center">
      智慧城市数据可视化平台
    </div>
    <div class="right">
      {{nowDate}}
    </div>
  </div>
</template>

<script>
import { formatDate, formatTime } from '@/utils/dateRangUtil'
export default {
  components: {},
  data () {
    return {
      nowDate: '',
      city: '潍坊',
      weather: {}
    };
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      // var lc = new T.LocalCity();
      // lc.location(function (e) {
      //   alert(e.cityName);
      // });
      this.getNowTime()
      this.getWeather()
    },
    getNowTime () {
      setInterval(() => {
        let date = new Date()
        this.nowDate = formatDate(date) + ' ' + formatTime(date)
      }, 1000);
    },
    getWeather () {
      let params = {
        city: this.city
      }
      this.$http.getWeather(params).then(res => {
        if (res.code === 200) {
          let data = JSON.parse(res.result.replace(/\\/g, ''))
          this.weather = data.data
        }
      })
    }
  },
}
</script>

<style lang='scss' scoped>
.title {
  width: 100%;
  height: 3.7326vw;
  color: #fff;
  background: url("../../../assets/screen/title.png") no-repeat;
  background-size: 100% 100%;
  position: relative;
  .left {
    position: absolute;
    left: 27%;
    top: 2.0833vw;
    font-size: 0.6597vw;
  }
  .center {
    position: absolute;
    left: 50%;
    top: 0.8681vw;
    font-size: 1.5625vw;
    transform: translate(-50%);
  }
  .right {
    position: absolute;
    right: 27%;
    top: 2.0833vw;
    font-size: 0.6597vw;
  }
}
</style>